import React from 'react';
import PropTypes from 'prop-types';
import CustomLink from 'components/ui/link';
import * as Styled from './styled';

const Section = ({ title, desc, banner, link, image, icon, idx, id }) => {
  return (
    <Styled.Section idx={idx} id={id}>
      <Styled.ContentBox>
        <img src={icon} alt={`icon - ${title}`} title="Icon" className="icon img-fluid" />
        <h2>{title}</h2>
        <p className="desc">{desc}</p>
        <Styled.MainImage
          src={image}
          alt={title}
          title={link ? link.text : banner.link.text}
          className="img-fluid mobile"
        />
        {link && (
          <CustomLink withArrow signUp sourceID={link.href}>
            {link.text}
          </CustomLink>
        )}
        {banner && (
          <Styled.Banner intercom={!!banner.img}>
            {banner.img && (
              <img src={banner.img} alt="Intercom" title="Integration with Intercom" />
            )}
            <p>{banner.text}</p>
            <CustomLink href={banner.link.href} withArrow>
              {banner.link.text}
            </CustomLink>
          </Styled.Banner>
        )}
      </Styled.ContentBox>
      <Styled.MainImage
        src={image}
        alt={title}
        title={link ? link.text : banner.link.text}
        className="img-fluid"
      />
    </Styled.Section>
  );
};

export default Section;

Section.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.node.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }),
  banner: PropTypes.shape({
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    text: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }).isRequired,
  }),
  idx: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

Section.defaultProps = {
  banner: null,
  link: null,
};
