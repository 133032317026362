import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CustomersRateAds } from 'components/CustomersRate';
import Button from 'components/ui/button';
import video from 'video/hero.mp4';
import video2 from 'video/hero2.webm';
import * as Styled from './styled';

const HeaderSection = ({ title }) => {
  const { videoPreview } = useStaticQuery(graphql`
    query {
      videoPreview: file(relativePath: { eq: "index/main_video.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Styled.Section>
      <Styled.ContentSection>
        <h1 className="line">
          Try <span>LiveSession</span> as a better alternative to {title}
        </h1>
        <p className="subtitle">A better way to analyze customer behaviour on your website.</p>
        <h4>Why use LiveSession:</h4>
        <ul>
          <li>Premium level session recordings</li>
          <li>Advanced clickmaps with heatmaps</li>
          <li>50+ Filters and Segments</li>
          <li>Custom searchable session data</li>
          <li>Error tracking</li>
        </ul>
        <Button signUp sourceID="hero">
          Start Free trial
        </Button>
        <p>
          Create free 14 days trial or <Link to="/pricing/">check pricing.</Link>
        </p>
      </Styled.ContentSection>
      <div>
        <Styled.ExtendImageWrapper>
          <Styled.ExtendVideo
            poster={videoPreview.childImageSharp.fluid.src}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
          >
            <source src={video2} type="video/webm" />
            <source src={video} type="video/mp4" />
          </Styled.ExtendVideo>
        </Styled.ExtendImageWrapper>
        <CustomersRateAds deleteTitle />
      </div>
    </Styled.Section>
  );
};

export default HeaderSection;

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
};
