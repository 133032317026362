import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 370px);
  grid-template-rows: repeat(3, 370px);
  grid-column-gap: 30px;
  grid-row-gap: 85px;
  padding-bottom: 0;
  ${media.desktopWide} {
    grid-template-columns: repeat(2, 370px);
    grid-template-rows: repeat(4, 370px);
    justify-content: center;
    grid-column-gap: 60px;
  }
  ${media.tablet} {
    grid-template-columns: repeat(1, 370px);
    grid-template-rows: repeat(9, 370px);
    grid-column-gap: 0;
  }
  ${media.mobile} {
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(9, auto);
    padding-bottom: 30px;
  }
`;
