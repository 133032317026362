import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import gasper from 'img/persons/gasper.png';

export const StyledOpinion = styled.section`
  padding: 9rem 0 7rem;
  border-bottom: ${(props) => (props.deleteLine ? null : '1px solid #e4e5e8')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
    padding: 90px 0 4rem !important;
  }
  ${media.mobile} {
    margin: 0;
  }
  h3 {
    text-align: center;
    font-size: 48px;
    line-height: 64px;
    font-weight: 800;
    width: 75%;
    margin: 0;
    ${media.desktop} {
      width: 100%;
      font-size: 28px;
      line-height: 40px;
    }
  }
  .person {
    margin-top: 3.5rem;
    display: flex;
    ${media.mobile} {
      margin-top: 40px;
    }
  }
`;

const Icon = styled.div`
  width: 64px;
  height: 64px;
  background-image: ${(props) => (props.photo ? `url(${props.photo})` : `url(${gasper})`)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${media.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const Name = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${media.mobile} {
    margin-left: 16px;
  }
  & p {
    line-height: normal;
  }
  p:nth-child(1) {
    font-size: 20px;
    font-weight: 800;
    margin: 0;
    ${media.mobile} {
      font-size: 16px;
    }
  }
  p:nth-child(2) {
    font-size: 16px;
    font-weight: 500;
    opacity: 0.48;
    margin: 0;
    ${media.mobile} {
      margin: 4px 0 0 0;
      font-size: 14px;
    }
  }
`;

const Opinion = ({ opinion, name, company, photo, deleteLine, opinionStyle }) => {
  return (
    <StyledOpinion deleteLine={deleteLine} style={opinionStyle}>
      <h3>
        {opinion
          ? `„${opinion}”`
          : `„We have tried out many, but LiveSession is the best session replay tool
        out there.”`}
      </h3>
      <div className="person">
        <Icon photo={photo} />
        <Name>
          <p>{name || 'Gasper Vidovic'}</p>
          <p>{company || 'Director of Product at Databox'}</p>
        </Name>
      </div>
    </StyledOpinion>
  );
};

export default Opinion;

Opinion.propTypes = {
  opinion: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  photo: PropTypes.node,
  deleteLine: PropTypes.bool,
  opinionStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Opinion.defaultProps = {
  deleteLine: false,
  opinionStyle: null,
  photo: null,
  company: null,
  name: null,
  opinion: null,
};
