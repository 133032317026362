/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout';
import styled from 'styled-components';
import Logo from 'components/layout/logo';
import { StyledButton } from 'components/ui/button';
import StartUsing from 'components/index/startusingCTA';
import TrustedBy from 'components/ui/trustedBy';
import Opinion, { StyledOpinion } from 'components/Opinion';
import FeatureCards from 'components/ui/features';
import SolutionsCarousel from 'components/ui/solutionsCarousel';
import HeaderSection from 'components/ads/HeaderSection';
import highPerformer from 'img/awards/fall-2020.svg';
import { media } from 'theme/breakpoints';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 27px 0 68px;
  width: 100%;
  & img {
    max-width: 80px;
  }
  ${media.mobile} {
    margin: 27px 0 40px;
    & img {
      max-width: 60px;
    }
  }
`;

const Container = styled.div`
  ${StyledButton} {
    padding: 15px 22px;
    font-size: 20px;
    max-height: 59px;
    width: 242px;
  }
  & h2 {
    text-align: center;
  }
  .solutions {
    margin-top: 42px;
    margin-bottom: -56px;
  }
  .features {
    margin-top: 40px;
  }
  ${media.desktop} {
    .solutions {
      margin-top: 42px;
      margin-bottom: 0;
    }
  }
  ${media.tablet} {
    .features {
      margin-top: 80px;
    }
  }
  ${media.mobile} {
    ${StyledOpinion} {
      padding-top: 0 !important;
    }
    .solutions {
      margin-top: 72px;
    }
  }
`;

const Ad = ({ data }) => {
  const post = data.markdownRemark.frontmatter;
  return (
    <Layout
      noIndex
      metaTitle={`Try LiveSession as a better alternative to ${post.title}`}
      metaDescription="A better way to analyze customer behaviour on your website."
      adPage
      hideHeader
    >
      <Container className="container">
        <Header>
          <Logo />
          <img
            src={highPerformer}
            alt="High Performer - Fall 2020"
            title="LiveSession Award - High Performer"
            className="img-fluid"
          />
        </Header>
        <HeaderSection title={post.title} />
        <TrustedBy hero style={{ paddingTop: 0 }} />
        <h2 className="features">Check LiveSession features</h2>
        <FeatureCards />
        <StartUsing buttonText="Start Free Trial" />
        <h2 className="solutions">One product, different solutions</h2>
        <SolutionsCarousel />
        <Opinion opinionStyle={{ paddingBottom: 80, paddingTop: 0 }} />
        <StartUsing buttonText="Start Free Trial" />
        <div style={{ marginBottom: 90 }} />
      </Container>
    </Layout>
  );
};

export default Ad;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
