import styled from 'styled-components';
import checkmark from 'img/ui/checkmarkGreen.svg';
import { StyledButton } from 'components/ui/button';
import { Video, ImageWrapper } from 'components/index/hero/styled';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: grid;
  grid-template-columns: auto 655px;
  grid-template-rows: auto;
  padding: 0 0 90px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 80px;
  ${media.desktopWide} {
    display: flex;
    flex-direction: column;
  }
  ${media.mobile} {
    padding: 0 0 74px;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.desktopWide} {
    margin-bottom: 40px;
  }
  h1 {
    margin-bottom: 16px;
    font-size: 36px;
    line-height: 44px;
    font-weight: 400;

    & span::after {
      background-color: #ffe478;
    }
    ${media.tablet} {
      font-size: 28px;
      line-height: 38px;
    }
  }
  p.subtitle {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 24px;
    margin-top: 0;
    margin-right: 16px;
    ${media.tablet} {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
  & h4 {
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  & ul {
    list-style: none;
    padding-left: 42px;
    margin: 0;
    & li {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: #000;
      margin-bottom: 16px;
      position: relative;
      &::before {
        content: '';
        background-image: url(${checkmark});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-42px, -50%);
        width: 18px;
        height: 14px;
      }
    }
  }
  & ${StyledButton} {
    width: min-content;
    margin-top: 16px;
  }
  & p {
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
  }
`;

export const ExtendImageWrapper = styled(ImageWrapper)`
  padding: 40px 62px;
  margin-bottom: 24px;
`;

export const ExtendVideo = styled(Video)`
  max-width: initial;
  min-height: initial;
`;
