import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import * as Styled from './styled';
import Card from './card';

const FeatureCards = () => {
  const { allFile } = useStaticQuery(graphql`
    query FeatureCards {
      allFile(filter: { relativePath: { regex: "/feature-cards/" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const files = transformImages(allFile.edges);
  const cardsInfo = [
    {
      title: 'Session recording',
      desc: `Play back your users' sessions and learn more about their behavior to make the product even better suited to their needs.`,
      img: files.session.node.childImageSharp.fluid,
    },
    {
      title: 'Filters and segments',
      desc: `Filter sessions and create user segments to analyze the behavior of specific user groups faster and more effectively.`,
      img: files.filters.node.childImageSharp.fluid,
    },
    {
      title: 'Clickmaps and heatmaps',
      desc: `Visualize most engagement places on your website and better analyze users interactions.`,
      img: files.clickmaps.node.childImageSharp.fluid,
    },
    {
      title: 'Custom properties',
      desc:
        'Add custom properties, such as subscription plan and other user data you need for your research.',
      img: files.customprop.node.childImageSharp.fluid,
    },
    {
      title: 'Inspect mode',
      desc: 'Find interactions with particular element by selecting it during session playback.',
      img: files.inspect.node.childImageSharp.fluid,
      imageStyle: {
        maxWidth: '75%',
      },
    },
    {
      title: 'Rage & Error Clicks',
      desc:
        'Add custom properties, such as subscription plan and other user data you need for your research.',
      img: files.rage.node.childImageSharp.fluid,
      imageStyle: {
        maxWidth: '65%',
      },
    },
    {
      title: 'Engagement score',
      desc:
        'Save your time with our engagement score. You’ll find the most interesting recordings in a few clicks.',
      img: files.engagement.node.childImageSharp.fluid,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Segment notifications',
      desc: 'Get the summary of segment activity directly to your email.',
      img: files.notifications.node.childImageSharp.fluid,
    },
    {
      title: 'User journey',
      desc: 'See the whole user journey as a timeline with most important events.',
      img: files.user.node.childImageSharp.fluid,
      imageStyle: {
        maxWidth: '100%',
      },
    },
  ];
  return (
    <Styled.Section>
      {cardsInfo.map((item) => (
        <Card {...item} key={item.title} />
      ))}
    </Styled.Section>
  );
};

export default FeatureCards;
