import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import Section from 'components/solutions/section';
import { data } from 'data/solutions/sections';
import map from 'lodash/map';
import * as Styled from 'components/solutions/section/styled';
import { media } from 'theme/breakpoints';
import arrow from 'img/ui/carouselArrow.svg';

const StyledSection = styled.section`
  ${Styled.Section} {
    padding: 0 15px;
  }
  & .alice-carousel {
    display: block;
    p {
      line-height: 30px !important;
    }
    & h2 {
      text-align: left;
      ${media.desktop} {
        text-align: center;
      }
    }
    & .alice-carousel__dots {
      margin-top: 48px;
    }
    & .alice-carousel__prev-btn-wrapper,
    .alice-carousel__next-btn-wrapper {
      height: 48px;
    }
    & .alice-carousel__next-btn,
    .alice-carousel__prev-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-150%);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      cursor: pointer;
      transition: all 0.2s ease;
      opacity: 0.2;
      width: auto;
      ${media.tablet} {
        display: none;
      }
      &:hover {
        opacity: 1;
      }
      & p {
        margin: 0;
        line-height: normal !important;
        padding: 24px;
        background: rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        & span {
          position: relative;
        }
        & span::after {
          content: '';
          background-image: url(${arrow});
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 11px;
          height: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-5px, -11px);
        }
      }
    }
    & .alice-carousel__next-btn {
      right: 23px;
    }
    & .alice-carousel__prev-btn {
      left: 23px;
      & span::after {
        transform: translate(-5px, -11px) rotate(180deg) !important;
      }
    }
  }

  ${media.mobile} {
    & .alice-carousel {
      margin-bottom: 0;
      & .alice-carousel__dots {
        margin-top: 24px;
      }
    }
  }
`;

const SolutionsCarousel = () => {
  const [currWidth, setCurrWidth] = useState(false);
  useEffect(() => {
    if (window && !currWidth) {
      setCurrWidth(window.innerWidth);
    }
  }, [currWidth]);
  return (
    <StyledSection>
      <AliceCarousel autoPlay autoHeight={currWidth <= 992} autoPlayInterval={6000}>
        {map(data, (section, idx) => (
          <Section {...section} idx={idx + 1} key={idx} />
        ))}
      </AliceCarousel>
    </StyledSection>
  );
};

export default SolutionsCarousel;
